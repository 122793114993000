.autrio-router {
	display: flex;
	text-decoration: none;
	height: rem-calc(175);
	border-radius: $global-radius;
	align-items: flex-end;
	margin-bottom: $global-margin;
	overflow: hidden;
	transition: all .5s;
	position: relative;
	
	@media screen and #{breakpoint(medium)} {
		height: 100%;
		margin-bottom: 0;
		border-radius: $global-radius*2;
		background-size: auto 100%;
		
		&:hover {
			background-size: auto 102%;
			box-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
			
			.title {
				max-height: none;
			}
			
			.bg {
				transform: scale(1.05);
			}
		}
	}
	
	.bg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		transition: all 1s;
	}
	
	.title {
		display: block;
		position: relative;
		z-index: 2;
		color: $white;
		background: $primary-color;
		padding: $global-padding;
		flex-grow: 2;
		border-radius: 0 0 $global-radius $global-radius;
		max-height: rem-calc(48);
		overflow: hidden;
		transition: all 3s;

		@media screen and #{breakpoint(medium)} {
			max-height: rem-calc(90);
			padding: $global-padding*2;
			border-radius: 0 0 $global-radius*2 $global-radius*2;
		}
		
		@media screen and #{breakpoint(large)} {
			max-height: rem-calc(112);
			padding: $global-margin*2;
		}
		
		h2 {
			color: $white;
			font-size: $global-font-size;
			margin: 0;
			
			@media screen and #{breakpoint(medium)} {
				font-size: rem-calc(32);
			}
		}
		
		p {
			font-size: rem-calc(18);
			margin: $global-margin*2 0;
		}
		
		.show-more {
			color: $secondary-color;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-family: 'Inter', sans-serif;
			
			svg {
				width: rem-calc(30);
			}
		}
	}
	
	&.autrio-router--sales .bg {
		background-image: url('../img/router-sales.jpg');
		
		@media screen and #{breakpoint(medium)} {
			background-image: url('../img/router-sales_large.jpg');
		}
	}
	
	&.autrio-router--build .bg {
		background-image: url('../img/router-build.jpg');
		
		@media screen and #{breakpoint(medium)} {
			background-image: url('../img/router-build_large.jpg');
		}
	}
	
	&.autrio-router--invest .bg {
		background-image: url('../img/router-invest.jpg');
		
		@media screen and #{breakpoint(medium)} {
			background-image: url('../img/router-invest_large.jpg');
		}
	}
}