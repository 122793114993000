#header {
	padding-top: $global-margin;
	padding-bottom: rem-calc(20);
	
	@media screen and #{breakpoint(medium)} {
		padding-top: $global-margin*2;
		padding-bottom: $global-margin*2;
		
		.grid-container {
			padding-left: $global-margin*2;
			padding-right: $global-margin*2;
		}
	}
}

.logo {
	height: rem-calc(32);
	background: url('../img/logo.svg') no-repeat;
	background-size: contain;
}

#wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

#content {
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	width: 100%;
	align-items: stretch;
	padding-bottom: $global-margin;
	
	> .grid-container {
		@media screen and #{breakpoint(medium)} {
			flex-grow: 2;
			display: flex;
			flex-direction: column;
			padding-left: $global-margin*2;
			padding-right: $global-margin*2;
		}
		
		> .grid-x {
			flex-grow: 2;
		}
	}
	
	@media screen and #{breakpoint(medium)} {
		flex-direction: row;
		padding-bottom: $global-margin*2;
	}
}